<template>
  <div>
    <h1>Histórico</h1>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="items.length > 0">
      <b-card no-body class="mb-0 m-2">
        <b-table
          :items="items"
          striped
          borderless
          :fields="tableColumns"
          show-empty
          empty-text="Não foram encontrados históricos"
          :busy="loading"
        >
          <!-- Column: createdAt -->
          <template #cell(created_at)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.created_at
              }}</span>
            </div>
          </template>
          <!-- Column: event -->
          <template #cell(event)="data">
            <div class="text-wrap">
              <span
                class="align-text-top text-indent"
                v-if="data.item.event_type === 'status_change'"
              >
                Mudança do status:
                <span class="text-primary">{{
                  data.item.previous_status
                }}</span>
                para:
                <span class="text-primary">{{ data.item.new_status }}</span>
              </span>
              <span
                class="align-text-top text-indent"
                v-else-if="data.item.event_type === 'comment'"
              >
                Comentário:
                <span class="text-primary">{{ data.item.comment }}</span>
              </span>
            </div>
          </template>
          <!-- Column: user -->
          <template #cell(user)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.user_name ? data.item.user_name : "Sistema"
              }}</span>
            </div>
          </template>
        </b-table>
      </b-card>
    </template>
    <template v-else>
      <span class="d-flex justify-content-center">
        Não foram encontrados históricos
      </span>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { avatarText } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "created_at", label: "Data" },
        { key: "event", label: "Evento" },
        { key: "user", label: "Responsável" },
      ],
      logs: undefined,
    };
  },
  computed: {
    ...mapGetters({}),
    items: function () {
      if (this.logs) {
        return _.orderBy(
          this.logs,
          function (element) {
            return new Date(element.created_at);
          },
          ["desc"]
        );
      }
      return [];
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getHistory: types.GET_TICKET_LOGS,
    }),
    get() {
      this.loading = true;
      this.getHistory(this.$route.params.id)
        .then((response) => {
          this.logs = response.data;
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar o histórico. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
